import React from 'react'
import DynamicHeroIcon from './DynamicHeroIcon'

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLElement> {
    ariaLabel?: string
    icon: any
    onClick?: (event: React.MouseEvent) => void
    size?: 's' | 'm' | 'l'
    variant?: 'primary' | 'secondary' | 'tertiary' | 'neutral'
}

const classes = {
    base: 'antialiased inline-flex justify-center font-medium text-center rounded-md transition-all duration-100 ease-linear items-center box-border align-middle p-0',
    disabled: 'cursor-not-allowed opacity-80',
    size: {
        s: 'h-8 w-8 leading-8 text-xs',
        m: 'h-10 w-10 leading-10 text-sm',
        l: 'h-12 w-12 leading-12 text-base',
    },
    icon: {
        s: 'w-5 h-5',
        m: 'w-6 h-6',
        l: 'w-6 h-6',
    },
    variant: {
        primary: 'text-white bg-crusta-500 hover:bg-crusta-700 focus:bg-crusta-800',
        secondary: 'text-gray-700 bg-gray-100 hover:bg-gray-200 focus:bg-gray-300',
        tertiary: 'text-gray-700 border border-gray-300 hover:bg-gray-100 focus:border-gray-400 focus:bg-gray-200',
        neutral: 'bg-transparent hover:opacity-80',
    },
}

const IconButton: React.FC<IconButtonProps> = ({
    ariaLabel,
    children,
    className = '',
    disabled = false,
    icon,
    size = 'm',
    onClick,
    variant = 'neutral',
    ...restProps
}) => {
    return (
        <button
            onClick={onClick}
            aria-label={ariaLabel}
            className={`
                ${classes.base}
                ${classes.variant[variant]} 
                ${classes.size[size]}
                ${disabled ? classes.disabled : ''}
                ${className}
            `}
            {...restProps}
        >
            {icon && <DynamicHeroIcon icon={icon} className={`${classes.icon[size]}`} aria-hidden="true" />}
            {children}
        </button>
    )
}

export default IconButton
