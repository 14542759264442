import React, { useState } from 'react'
import { Api } from '../../../types/api'
import Collection = Api.Collection
import Topic = Api.Topic
import Button from './Button'
import { useTranslation } from 'next-i18next'

interface TopicPickerProps {
    topics: Collection<Topic> | null
    onSelect: (topic: Topic) => void
}
const TopicPicker: React.FC<TopicPickerProps> = (props) => {
    const { t } = useTranslation(['common'])
    const [selectedTopic, setSelectedTopic] = useState<Topic | null>()

    return (
        <>
            <div className="max-w-prose space-y-4">
                <p>{t('welcome-message')}</p>
                <h2>{t('learn-more')}</h2>
                <p>{t('pick-topic-description')}</p>
            </div>
            {selectedTopic && (
                <div>
                    <div className="transition-all duration-200 relative flex items-center space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm bg-gray-50 ">
                        <div className="flex-shrink-0">
                            {/*<img className="h-10 w-10 rounded-full" src={topic} alt="" />*/}
                        </div>
                        <div className="min-w-0 flex-1">
                            <p className="text-lg font-medium text-gray-900">{selectedTopic.title}</p>
                            <p className="text-base text-gray-500">{selectedTopic.description}</p>
                        </div>
                    </div>

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between">
                        <Button
                            variant="primary"
                            size="l"
                            className="mt-3 w-full sm:mt-0 sm:w-auto"
                            onClick={() => props.onSelect(selectedTopic)}
                        >
                            {t('pick-topic')}
                        </Button>
                        <Button
                            variant="tertiary"
                            className="mt-3 w-full sm:mt-0 sm:w-auto"
                            size="l"
                            onClick={() => setSelectedTopic(null)}
                        >
                            {t('back')}
                        </Button>
                    </div>
                </div>
            )}

            {props.topics?.['hydra:member'] && !selectedTopic && (
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {props.topics?.['hydra:member'].map((topic) => (
                        <div
                            key={topic['@id']}
                            className="transition-all duration-200 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-gray-50 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 hover:bg-crusta-700 cursor-pointer"
                        >
                            <div className="flex-shrink-0">
                                {/*<img className="h-10 w-10 rounded-full" src={topic} alt="" />*/}
                            </div>
                            <div className="min-w-0 flex-1">
                                <a
                                    onClick={() => setSelectedTopic(topic)}
                                    className="group focus:outline-none hover:no-underline hover:text-white transition-all duration-200"
                                >
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    <p className="text-lg font-medium text-gray-900 group-hover:text-white">
                                        {topic.title}
                                    </p>
                                    <p className="text-base text-gray-500 truncate group-hover:text-gray-100 ">
                                        {topic.description}
                                    </p>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}

export default TopicPicker
