import React from 'react'
import IconButton from './IconButton'
import DynamicHeroIcon from './DynamicHeroIcon'

export interface AttentionBoxProps {
    className?: string
    icon?: any
    onClose?: (event: React.MouseEvent) => void
    text?: string
    title?: string
    variant?: 'success' | 'warning' | 'danger' | 'info' | 'neutral' | 'white'
    children?: React.ReactNode
}

const classes = {
    base: 'relative p-4 rounded-md space-y-4',
    dismiss: 'pr-16',
    variant: {
        success: {
            default: 'bg-green-50',
            icon: 'text-sea-green-500',
        },
        warning: {
            default: 'bg-yellow-50',
            icon: 'text-crusta-500',
        },
        danger: {
            default: 'bg-red-50',
            icon: 'text-sweet-pink-500',
        },
        info: {
            default: 'bg-mariner-100',
            icon: 'text-mariner-500',
        },
        neutral: {
            default: 'bg-gray-100',
            icon: 'text-gray-800',
        },
        white: {
            default: 'bg-white',
            icon: 'text-gray-800',
        },
    },
}

const AttentionBox: React.FC<AttentionBoxProps> = ({
    className = '',
    icon,
    onClose,
    text,
    title,
    variant = 'info',
    children,
}) => {
    return (
        <div
            className={`
                ${classes.base}
                ${classes.variant[variant]['default']}
                ${onClose ? classes.dismiss : ''}
                ${className}
            `}
        >
            <div className="flex items-center ">
                {icon && (
                    <DynamicHeroIcon
                        icon={icon}
                        className={`w-6 h-6 mr-2 ${classes.variant[variant]['icon']}`}
                        aria-hidden="true"
                    />
                )}
                {title && <h3>{title}</h3>}
            </div>
            {text && <p>{text}</p>}
            {children}
            {onClose && <IconButton className="absolute top-0 right-4" size="s" icon={'XIcon'} onClick={onClose} />}
        </div>
    )
}

export default AttentionBox
