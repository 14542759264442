import React from 'react'

export interface ArticleCardProps {
    alt?: string
    className?: string
    src?: string
    title: string
    providerName?: string
    showProvider?: boolean
}

const TrainingCard: React.FC<ArticleCardProps> = ({
    alt,
    className = '',
    src,
    title,
    providerName = '',
    showProvider = true,
}) => {
    return (
        <div
            title={title}
            className={`group flex flex-col relative h-full text-gray-700 bg-white transition-all duration-300 ease-linear no-underline hover:no-underline hover:shadow-gray-300
                ${className}
            `}
        >
            <figure className="w-full bg-gray-200 aspect-w-16  rounded-lg aspect-h-9 xl:aspect-w-16 xl:aspect-h-9 overflow-hidden">
                <img
                    src={src ? src : '/../assets/img/logo-stamp-dark.svg'}
                    alt={alt}
                    className={`transition-transform duration-300 ease-linear
                    ${src ? 'object-cover object-center group-hover:scale-105' : 'scale-[0.6] group-hover:scale-[0.65]'}
                `}
                />
            </figure>
            <div className="pt-4 flex flex-col justify-between">
                {showProvider && <span className="text-sm text-gray-500 block min-h-[1rem]">{providerName}</span>}
                <h3 className="line-clamp-2 text-xl transition-colors duration-200 ease-in-out group-hover:text-mariner-700/75 ">
                    {title}
                </h3>
            </div>
        </div>
    )
}

export default TrainingCard
