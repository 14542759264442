import React from 'react'
import Link from 'next/link'

export interface HorizontalScrollerProps {
    children?: React.ReactNode
    className?: string
    title?: string
    subTitle?: string
    button?: string
    buttonLink?: string
    showMoreButton?: boolean
}

const CategoryGrid: React.FC<HorizontalScrollerProps> = ({
    children,
    className = '',
    title,
    subTitle,
    button,
    buttonLink,
    showMoreButton = false,
}) => {
    return (
        <div className={`relative  ${className}`}>
            <div className="relative w-full">
                {title && <h2>{title}</h2>}
                {subTitle && <p>{subTitle}</p>}
                {button && buttonLink && showMoreButton && (
                    <Link href={buttonLink}>
                        <a className="">{button}</a>
                    </Link>
                )}
            </div>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 py-4">{children}</div>
        </div>
    )
}

export default CategoryGrid
