import React, { ReactNode } from 'react'
import NavBar from '../components/layouts/navbar/NavBar'
import Footer from '../components/layouts/footer/footer'
import RegisterMsg from '../components/elements/RegisterMsg'
import Head from 'next/head'
import NavBarSSO from '../components/layouts/navbar/NavBarSSO'

interface DefaultLayoutProps {
    children: ReactNode
    noMain?: boolean
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, noMain = false }) => {
    const authMethod = process.env.NEXT_PUBLIC_AUTH_METHOD || 'CREDENTIALS'
    return (
        <>
            <Head>
                <meta property="og:title" content="De Digitale Academie Noord-Nederland" key="title" />
                <meta property="og:image" content="/assets/img/meta-image-social.jpg" key="image" />
                <meta name="twitter:title" content="De Digitale Academie Noord-Nederland" key="title-twitter" />
                <meta name="twitter:image" content="/assets/img/meta-image-social.jpg" key="image-twitter" />
            </Head>

            <div className="min-h-full">
                {authMethod === 'OIDC' ? (
                    <>
                        <NavBarSSO />
                    </>
                ) : (
                    <>
                        <NavBar />
                        <RegisterMsg />
                    </>
                )}

                {noMain ? (
                    <>{children}</>
                ) : (
                    <main className="p-4 lg:p-8 mt-[var(--dann-navbar-height-lg)] min-h-[calc(100vh-var(--dann-navbar-height-lg)-var(--dann-footer-height-lg))]">
                        {children}
                    </main>
                )}
                <Footer />
            </div>
        </>
    )
}

export default DefaultLayout
