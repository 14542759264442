import React from 'react'

export interface SkeletonProps {
    className?: string
    type: 'text' | 'circle' | 'rectangle'
}

const classes = {
    base: 'bg-gray-100 overflow-hidden skeleton',
    type: {
        text: 'rounded-lg',
        circle: 'rounded-full',
        rectangle: 'rounded-lg',
    },
}

const Skeleton: React.FC<SkeletonProps> = ({ className = '', type = 'rectangle' }) => {
    return (
        <div
            className={`
                ${classes.base}
                ${classes.type[type]}
                ${className}
            `}
        />
    )
}

export default Skeleton
