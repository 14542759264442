import React from 'react'
import * as HIcons from '@heroicons/react/outline'
import * as SIcons from '@heroicons/react/solid'

export interface DynamicHeroIconProps {
    className?: string
    icon: string
    type?: 'outline' | 'solid'
}

const DynamicHeroIcon: React.FC<DynamicHeroIconProps> = ({ className = '', icon, type = 'outline' }) => {
    const { ...icons } = type === 'outline' ? HIcons : SIcons

    // @ts-ignore
    const Icon: JSX.Element = icons[icon]

    return (
        <>
            {/* @ts-ignore */}
            <Icon className={`${className}`} aria-hidden="true" />
        </>
    )
}

export default DynamicHeroIcon
