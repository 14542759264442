import React, { useContext, useEffect, useState } from 'react'
import DefaultLayout from '../layouts/DefaultLayout'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { getSession, useSession } from 'next-auth/react'
import { Api } from '../../types/api'
import Collection = Api.Collection
import Topic = Api.Topic
import { fetcher } from '../api/io'
import TopicPicker from '../components/elements/TopicPicker'
import { toast } from 'react-toastify'
import User = Api.User
import { useTranslation } from 'next-i18next'
import { appContext } from '../state/AppContext'
import { USER_ACTION_CHANGE } from '../state/userReducer'
import AttentionBox from '../components/elements/AttentionBox'
import Category = Api.Category
import TrainingCard from '../components/elements/cards/TrainingCard'
import Link from 'next/link'
import { routes } from '../utils/routes'
import Training = Api.Training
import TrainingProgress = Api.TrainingProgress
import Skeleton from '../components/elements/Skeleton'
import Short = Api.Short
import ShortCard from '../components/elements/cards/ShortCard'
import MediaObject = Api.MediaObject
import Button from '../components/elements/Button'
import CategoryGrid from '../components/elements/containers/CategoryGrid'
import { redirect } from 'next/dist/server/api-utils'
import { router } from 'next/client'
import { useRouter } from 'next/router'
import Head from 'next/head'

interface ServerSideProps {
    topics: Collection<Topic> | null
    trainingCategories: Collection<Category> | null
    shorts: Collection<Short> | null
    activeTrainings: Collection<TrainingProgress> | null
    favoriteTrainings: Collection<Training> | null
}

type FormData = {
    topics: string[]
}

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async (context) => {
    context.res.setHeader('Cache-Control', 'no-store')
    const session = await getSession({ req: context.req })

    let topics: Collection<Topic> | null = null
    let trainingCategories: Collection<Category> | null = null
    let shorts: Collection<Short> | null = null
    let favoriteTrainings: Collection<Training> | null = null
    let activeTrainings: Collection<TrainingProgress> | null = null

    if (session?.user) {
        try {
            topics = await fetcher<null, Collection<Topic>>({
                url: '/topics?thumbnail=large',
                accessToken: session.user.accessToken,
            })

            trainingCategories = await fetcher<null, Collection<Category>>({
                url: '/categories',
                accessToken: session.user.accessToken,
            })

            shorts = await fetcher<null, Collection<Short>>({
                url: '/shorts',
                accessToken: session.user.accessToken,
            })

            activeTrainings = await fetcher<null, Collection<TrainingProgress>>({
                url: '/users/' + session.user.id + '/training_progresses?state[]=in_progress&thumbnail=large',
                accessToken: session.user.accessToken,
            })

            favoriteTrainings = await fetcher<null, Collection<Training>>({
                url: '/users/' + session.user.id + '/favorite_trainings?thumbnail=large',
                accessToken: session.user.accessToken,
            })
        } catch (e) {
            console.log(e)
        }
    } else {
        try {
            trainingCategories = await fetcher<null, Collection<Category>>({
                url: '/categories',
            })
            shorts = await fetcher<null, Collection<Short>>({
                url: '/shorts',
            })
        } catch (e) {
            console.log(e)
        }
    }

    return {
        props: {
            ...(await serverSideTranslations(context.locale ? context.locale : '', ['common', 'meta'])),
            topics,
            trainingCategories,
            shorts,
            activeTrainings,
            favoriteTrainings,
        },
    }
}

const Index: React.FC<InferGetServerSidePropsType<typeof getServerSideProps>> = (props) => {
    const { t } = useTranslation(['common'])
    const { state, dispatch } = useContext(appContext)
    const { data: session, status } = useSession()
    const [selectedTopic, setSelectedTopic] = useState<Topic | null>()
    const [categories, setCategories] = useState<Category[] | null>([])
    const [shorts, setShorts] = useState<Collection<Short> | null>(props.shorts)
    const authMethod = process.env.NEXT_PUBLIC_AUTH_METHOD || 'CREDENTIALS'
    const router = useRouter()

    const user = state.userState.user

    if (authMethod === 'OIDC' && !user) {
        void router.replace(routes.signIn.path)
    }

    const getCategoryDetails = async () => {
        if (props.trainingCategories) {
            const cloneCategories: Collection<Category> = JSON.parse(JSON.stringify(props.trainingCategories))
            try {
                const cats = []
                for (const item of cloneCategories['hydra:member']) {
                    const response = await fetcher<null, Category>({
                        url: '/categories/' + item.uuid + '?thumbnail=large',
                        accessToken: session?.user.accessToken,
                    })

                    item.trainings = response?.trainings ? response?.trainings : []

                    if (item.trainings.length > 0) cats.push(item)
                }

                setCategories(cats)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const getShortVideos = async () => {
        if (props.shorts) {
            const cloneShorts: Collection<Short> = JSON.parse(JSON.stringify(shorts))
            try {
                for (const item of cloneShorts['hydra:member']) {
                    if (item.media) {
                        const response = await fetcher<null, MediaObject>({
                            url: item.media,
                        })
                        item.media = response?.contentUrl ? response?.contentUrl : ''
                    }
                }
                setShorts(cloneShorts)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const onTopicSelect = async (topic: Topic) => {
        const data = { topics: [topic['@id']] }
        try {
            const response = await fetcher<FormData, User>({
                url: '/users/' + session?.user.id,
                accessToken: session?.user.accessToken,
                method: 'PUT',
                params: data,
            })
            if (response) {
                dispatch({
                    type: USER_ACTION_CHANGE,
                    user: response,
                })
                setSelectedTopic(topic)
                // toast.success(t('save-succes'))
            }
        } catch (e) {
            console.log('error', e)
            toast.error(<>t('save-error')</>)
        }
    }
    useEffect(() => {
        getCategoryDetails().catch((e) => console.log(e))
    }, [props.trainingCategories])

    useEffect(() => {
        getShortVideos().catch((e) => console.log(e))
    }, [props.shorts, status])

    useEffect(() => {
        document.addEventListener(
            'play',
            (e) => {
                const videos = document.getElementsByTagName('video')
                for (let i = 0; i < videos.length; i++) {
                    if (videos[i] !== e.target) {
                        videos[i].pause()
                    }
                }
            },
            true
        )
        return () => {
            document.removeEventListener(
                'play',
                (e) => {
                    const videos = document.getElementsByTagName('video')
                    for (let i = 0; i < videos.length; i++) {
                        if (videos[i] !== e.target) {
                            videos[i].pause()
                        }
                    }
                },
                true
            )
        }
    }, [])

    return (
        <>
            <Head>
                <title>{t('index.title', { ns: 'meta' })}</title>
                <meta name="description" content={t('index.description', { ns: 'meta' })} />
            </Head>
            <DefaultLayout>
                <main className="min-h-screen space-y-24">
                    {session?.user ? (
                        <div className="space-y-8">
                            {user && (
                                <div className="space-y-3">
                                    <h2>
                                        {t('welcome')} {user?.firstName},
                                    </h2>
                                    {!selectedTopic && <p>{t('overview')}</p>}
                                </div>
                            )}

                            {selectedTopic && (
                                <AttentionBox
                                    variant="success"
                                    icon="ThumbUpIcon"
                                    title={`Super dat je meer wilt weten over “${selectedTopic.title}”`}
                                    text="Klik hieronder op de knop voor het opleidingsaanbod op basis van jouw gekozen thema. Neem gerust een kijkje. Zou je toch een ander onderwerp willen kiezen of staat een bepaalde opleiding er niet tussen? Klik dan op Opleidingsaanbod boven in het menu. "
                                    className="block w-full"
                                    onClose={() => setSelectedTopic(null)}
                                >
                                    <Link href={routes.trainings.path + '?topic=' + selectedTopic['@id']}>
                                        <a className="mt-6 block">
                                            <Button variant="primary">Bekijk het opleidingsaanbod van dit thema</Button>{' '}
                                        </a>
                                    </Link>
                                </AttentionBox>
                            )}

                            {state.userState.user?.topics.length === 0 && (
                                <TopicPicker topics={props.topics} onSelect={onTopicSelect} />
                            )}
                        </div>
                    ) : (
                        <div className="space-y-3">
                            <h2>{t('welcome')},</h2>
                        </div>
                    )}

                    {shorts?.['hydra:member'] && shorts?.['hydra:member'].length !== 0 && (
                        <CategoryGrid
                            className="mt-10"
                            title={t('shorts')}
                            subTitle={t('shorts-subtext')}
                            button={t('showMore')}
                            buttonLink={routes.shortsOverview.path}
                            showMoreButton={shorts?.['hydra:member'].length > 4}
                        >
                            {shorts?.['hydra:member'].slice(0, 4).map((short) => (
                                <ShortCard
                                    key={short.uuid}
                                    title={short.title}
                                    description={short.description}
                                    src={`${short.media ? process.env.NEXT_PUBLIC_URL + short.media : ''}`}
                                    mediaLink={short.mediaUrl}
                                />
                            ))}
                        </CategoryGrid>
                    )}

                    {session?.user && (
                        <>
                            {props.activeTrainings?.['hydra:member'].length !== 0 && (
                                <CategoryGrid
                                    className="mt-10"
                                    title={t('active_trainings')}
                                    subTitle={t('active_trainings-subtext')}
                                >
                                    {props.activeTrainings?.['hydra:member'].map((trainingProgress) => (
                                        <Link
                                            key={trainingProgress.training['@id']}
                                            href={
                                                routes.trainings.path +
                                                '/' +
                                                trainingProgress.training['@id'].replace('/api/trainings/', '')
                                            }
                                        >
                                            <a className="hover:no-underline group flex flex-col relative overflow-hidden">
                                                <TrainingCard
                                                    title={trainingProgress.training.title}
                                                    providerName={
                                                        trainingProgress.training.provider
                                                            ? trainingProgress.training.provider.name
                                                            : ''
                                                    }
                                                    src={`${
                                                        trainingProgress.training.image
                                                            ? process.env.NEXT_PUBLIC_URL +
                                                              trainingProgress.training.image.thumbnailUrl
                                                            : ''
                                                    }`}
                                                />
                                            </a>
                                        </Link>
                                    ))}
                                </CategoryGrid>
                            )}

                            {props.favoriteTrainings?.['hydra:member'] &&
                                props.favoriteTrainings?.['hydra:member'].length !== 0 && (
                                    <CategoryGrid
                                        className="mt-10"
                                        title={t('favorites')}
                                        subTitle={t('favorites-subtext')}
                                        button={t('showMoreFavorite')}
                                        buttonLink={routes.favorites.path}
                                        showMoreButton={props.favoriteTrainings?.['hydra:member'].length > 4}
                                    >
                                        {props.favoriteTrainings?.['hydra:member'].slice(0, 4).map((training) => (
                                            <Link
                                                href={routes.trainings.path + '/' + training.uuid}
                                                key={training.uuid}
                                            >
                                                <a className="hover:no-underline group flex flex-col relative overflow-hidden">
                                                    <TrainingCard
                                                        title={training.title}
                                                        providerName={training.provider ? training.provider.name : ''}
                                                        src={`${
                                                            training.image
                                                                ? process.env.NEXT_PUBLIC_URL +
                                                                  training.image.thumbnailUrl
                                                                : ''
                                                        }`}
                                                    />
                                                </a>
                                            </Link>
                                        ))}
                                    </CategoryGrid>
                                )}
                        </>
                    )}

                    {categories?.map((category: Category) => (
                        <CategoryGrid
                            className="mt-10"
                            key={category.uuid}
                            title={category.title}
                            subTitle={category.subtitle}
                            button={t('showMore')}
                            buttonLink={routes.categories.path + '/' + category.uuid}
                            showMoreButton={category.trainings?.length > 4}
                        >
                            {category.trainings === undefined && (
                                <div className="flex shrink-0 snap-start">
                                    <Skeleton
                                        type="rectangle"
                                        className="w-[26rem] h-[24rem] rounded-lg w-full h-full"
                                    />
                                </div>
                            )}
                            {category.trainings?.slice(0, 4).map((training) => (
                                <Link
                                    key={training.training['@id']}
                                    href={
                                        routes.trainings.path +
                                        '/' +
                                        training.training['@id'].replace('/api/trainings/', '')
                                    }
                                >
                                    <a className="hover:no-underline">
                                        <TrainingCard
                                            title={training.training.title}
                                            providerName={
                                                training.training.provider ? training.training.provider.name : ''
                                            }
                                            src={`${
                                                training.training.image && training.training.image.thumbnailUrl
                                                    ? process.env.NEXT_PUBLIC_URL + training.training.image.thumbnailUrl
                                                    : ''
                                            }`}
                                        />
                                    </a>
                                </Link>
                            ))}
                        </CategoryGrid>
                    ))}
                </main>
            </DefaultLayout>
        </>
    )
}

export default Index
