import React from 'react'

export interface ShortProps {
    className?: string
    src?: string
    title: string
    description?: string
    mediaLink?: string
}

const ShortCard: React.FC<ShortProps> = ({ className = '', src, title, description, mediaLink }) => {
    return (
        <div
            title={title}
            className={`group flex p-3 flex-col relative bg-gray-100 h-full rounded-lg text-gray-700 transition-all duration-300 ease-linear no-underline hover:no-underline
                ${className}
            `}
        >
            {mediaLink ? (
                <iframe
                    className="w-full bg-gray-200 rounded-lg object-cover h-56 w-full lg:w-100 object-center"
                    src={'https://www.youtube.com/embed/' + mediaLink.replace('https://www.youtube.com/watch?v=', '')}
                    title={title}
                    allowFullScreen
                ></iframe>
            ) : (
                <>
                    {src ? (
                        <>
                            {src.endsWith('mp4') || src.endsWith('webm') || src.endsWith('ogg') ? (
                                <video
                                    src={src}
                                    className="w-full bg-gray-200 rounded-lg object-cover h-56 w-full lg:w-100 object-center"
                                    controls
                                ></video>
                            ) : (
                                <figure className="w-full bg-gray-200 aspect-w-16  rounded-lg aspect-h-9 overflow-hidden">
                                    <img
                                        src={'/../assets/img/logo-stamp-dark.svg'}
                                        className={
                                            'transition-transform duration-300 ease-linearobject-cover scale-[0.6] group-hover:scale-[0.65]'
                                        }
                                        alt={title}
                                    />
                                </figure>
                            )}
                        </>
                    ) : (
                        <figure className="w-full bg-gray-200 aspect-w-16  rounded-lg aspect-h-9 overflow-hidden">
                            <img
                                src={src ? src : '/../assets/img/logo-stamp-dark.svg'}
                                className={`transition-transform duration-300 ease-linear
                    ${src ? 'object-cover object-center group-hover:scale-105' : 'scale-[0.6] group-hover:scale-[0.65]'}
                `}
                                alt={title}
                            />
                        </figure>
                    )}
                </>
            )}

            <div className="pt-4 flex flex-col justify-between">
                <h3 className="line-clamp-2 text-xl transition-colors duration-200 ease-in-out group-hover:text-mariner-700/75 ">
                    {title}
                </h3>
                <p className="pt-2 pb-5">{description}</p>
            </div>
        </div>
    )
}

export default ShortCard
