import React from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { Disclosure } from '@headlessui/react'
import FooterNavItem from '../../elements/footer/footerNavItem'
import { useRouter } from 'next/router'
import { routes } from '../../../utils/routes'
import Can from '../../elements/auth/Can'
import { footerNavigation } from '../../../utils/navigation'
import FooterLogo from '../../elements/footer/FooterLogo'

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
}

const Footer: React.FC = () => {
    const { t } = useTranslation(['common'])
    const router = useRouter()

    return (
        <Disclosure as="footer" className=" bg-gray-100 inset-x-0 bottom-0 z-30 h-[var(--dann-footer-height-lg)]">
            <div className="mx-auto w-full pl-4  ">
                <div className="flex justify-between h-[var(--dann-navbar-height-lg)]">
                    <div className="flex">
                        <div className="flex justify-center w-full lg:justify-start">
                            {/* Start FooterLogo */}
                            <div className="flex items-center">
                                {router.pathname === routes.index.path ? (
                                    <a href="https://dann.nl/">
                                        <FooterLogo />
                                    </a>
                                ) : (
                                    <Link href="/">
                                        <a>
                                            <FooterLogo />
                                        </a>
                                    </Link>
                                )}
                            </div>
                            {/* End FooterLogo */}

                            {/* Start footer menu */}
                            <div className="hidden px-5 lg:ml-5 mr-5 lg:flex sm:space-x-4 xl:space-x-10">
                                {footerNavigation.map((item) => (
                                    <Can roles={item.roles} key={item.name}>
                                        <FooterNavItem key={item.name} href={item.href} name={t(item.name)} />
                                    </Can>
                                ))}
                            </div>
                            {/* End footer menu */}
                        </div>
                    </div>
                    {/* Start footer call to action*/}
                    <div className="flex flex-grow justify-end">
                        <div className="hidden space-x-3 lg:flex">
                            <div className="h-full flex text-sm font-medium bg-mariner-200 px-10 text-mariner-700/75 inline-flex items-center space-x-6 ">
                                <div className="flex space-x-1">
                                    <p className="text-mariner-700/75">{t('help')}</p>
                                    <a href="tel:0505955504" className="hover:no-underline font-bold">
                                        {t('call')}
                                    </a>
                                </div>
                                <div className="flex space-x-1">
                                    <p className="text-mariner-700/75">{t('mail-to')}</p>
                                    <a href="mailto:info@dann.nl" className="hover:no-underline font-bold">
                                        info@dann.nl
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End footer call to action*/}
                </div>
            </div>

            <div className="lg:hidden bg-gray-100 space-y-1 pb-3 px-4">
                {footerNavigation.map((item) => (
                    <Can roles={item.roles} key={item.name}>
                        <Link href={item.href} key={item.name}>
                            <Disclosure.Button
                                as="a"
                                href={item.href}
                                className={classNames(
                                    router.asPath === item.href
                                        ? '  text-carto-500'
                                        : 'border-transparent text-gray-600  hover:text-gray-800',
                                    'block  py-2 text-base font-medium'
                                )}
                                aria-current={router.asPath === item.href ? 'page' : undefined}
                            >
                                {t(item.name)}
                            </Disclosure.Button>
                        </Link>
                    </Can>
                ))}
            </div>
            <div className="lg:hidden space-x-3 lg:flex" id="mobile-footer">
                <div className="h-full bg-mariner-200 px-5 py-5 text-mariner-700/75 items-center">
                    <h4 className="font-medium text-mariner-700/75">{t('help')}</h4>
                    <a href="tel:0505955504" className="hover:no-underline font-bold">
                        {t('call')}
                    </a>
                    <h4 className="font-medium text-mariner-700/75">{t('mail-to')}</h4>
                    <a href="mailto:info@dann.nl" className="hover:no-underline font-bold">
                        info@dann.nl
                    </a>
                </div>
            </div>
        </Disclosure>
    )
}

export default Footer
