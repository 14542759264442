import React from 'react'

const FooterLogo: React.FC = () => {
    return (
        <figure>
            <svg width="32" viewBox="0 0 43 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.8983 0.0222778H35.5956V4.31933H39.8983V0.0222778Z" fill="#007DA5"></path>
                <path
                    d="M33.8196 4.80563H29.7754C29.606 4.80546 29.4383 4.83863 29.2817 4.90324C29.1252 4.96786 28.9829 5.06265 28.8631 5.1822C28.7433 5.30176 28.6482 5.44372 28.5833 5.59999C28.5185 5.75626 28.4851 5.92375 28.4851 6.09291V10.1304C28.4851 10.4716 28.3495 10.7988 28.108 11.0402C27.8666 11.2816 27.5391 11.4173 27.1974 11.4177H23.2438C22.9075 11.4175 22.5744 11.4836 22.2636 11.612C21.9528 11.7405 21.6704 11.9289 21.4326 12.1664C21.1948 12.4039 21.0061 12.6859 20.8775 12.9963C20.7488 13.3067 20.6827 13.6393 20.6829 13.9752V18.7232C20.6829 19.0646 20.5471 19.392 20.3054 19.6334C20.0636 19.8749 19.7358 20.0105 19.3939 20.0105H13.3624C13.0206 20.0105 12.6927 19.8749 12.451 19.6334C12.2093 19.392 12.0734 19.0646 12.0734 18.7232V12.6932C12.0734 12.3518 12.2093 12.0243 12.451 11.7829C12.6927 11.5415 13.0206 11.4059 13.3624 11.4059H18.1233C18.8005 11.4056 19.4499 11.1374 19.9296 10.6601C20.4093 10.1827 20.6801 9.53511 20.6829 8.85886V6.17288C20.6811 5.68235 20.5379 5.20271 20.2704 4.79127C20.003 4.37983 19.6226 4.05397 19.1747 3.85263C18.8325 3.76523 18.4825 3.68964 18.1246 3.62584C17.0138 3.42797 15.8874 3.33014 14.7591 3.33352C4.87768 3.33352 0 10.1697 0 18.6013C0 29.4881 7.90195 33 14.6396 33C21.3773 33 29.5168 29.4881 29.5168 18.6013C29.5347 16.5498 29.2141 14.5094 28.5678 12.5621C28.5678 12.549 28.5573 12.5359 28.5533 12.5228C28.6504 12.2036 28.8476 11.9241 29.1159 11.7253C29.3841 11.5266 29.7092 11.4192 30.0432 11.419H33.8196C33.9888 11.4192 34.1564 11.386 34.3127 11.3215C34.4691 11.2569 34.6112 11.1622 34.7309 11.0428C34.8507 10.9234 34.9456 10.7816 35.0104 10.6255C35.0752 10.4694 35.1086 10.302 35.1086 10.133V6.09291C35.1087 5.92382 35.0755 5.75635 35.0108 5.60009C34.9461 5.44383 34.8511 5.30185 34.7314 5.18228C34.6117 5.06271 34.4695 4.9679 34.313 4.90327C34.1566 4.83864 33.9889 4.80546 33.8196 4.80563Z"
                    fill="#FF7F31"
                ></path>
                <path
                    d="M41.6533 4.61035C40.7379 4.61035 40.2803 5.07615 40.2803 6.00775V6.13883C40.2803 7.08791 40.7379 7.56245 41.6533 7.56245C42.5511 7.56245 43 7.08791 43 6.13883V6.00775C43 5.07702 42.5511 4.61123 41.6533 4.61035Z"
                    fill="#007DA5"
                ></path>
                <path
                    d="M23.081 0C21.8944 0 21.3015 0.603003 21.3024 1.80901V1.98073C21.3024 3.20946 21.8952 3.82383 23.081 3.82383C24.2431 3.82383 24.8241 3.20946 24.8241 1.98073V1.81294C24.8241 0.606936 24.2431 0.00393247 23.081 0.00393247"
                    fill="#007DA5"
                ></path>
            </svg>
        </figure>
    )
}

export default FooterLogo
